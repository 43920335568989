<template>
  <section>
    <div class="wrap">
      <div class="title">
        <h2>Recruitment Support<br>Service</h2>
        <p>求人支援サービス</p>
      </div>

      <div class="video-content tb-sp-only">
        <a href="/closed/recruit/" class="absolute-content">
          <video loop autoplay muted playsinline>
            <source src="/video/maru2.mp4" />
            このブラウザではサポートされていません。
          </video>
        </a>
      </div>

      

      <div class="content">
        <h3>求人・採用にお困りの経営者様向け</h3>
        <p>
          SNS不要、リスト不要、人気・知名度不要の求人支援サービスです。
        </p>
        <p>
          応募数・採用数ともに増加成功率100％の実績を誇ります。
        </p>
        <p>
          初回無料相談を実施していますので、ご興味ある方はお気軽にお問い合わせください。
        </p>
      </div>

      <a href="/closed/recruit/" class="lp-link-sp">詳しくはこちら</a>

      <div class="video-content pc-only">
        <a href="/closed/recruit/" target="_blank" rel="noopener noreferrer" class="absolute-content">
          <video loop autoplay muted playsinline>
            <source src="/video/maru2.mp4" />
            このブラウザではサポートされていません。
          </video>
          <!-- <a href="/closed/recruit/" class="lp-link-sp">詳しくはこちら</a> -->
          <p>詳しくはこちら</p>
        </a>
      </div>
      
    </div>
  </section>
</template>

<script>
export default {
  name: "LookingForPartners",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 75px 0;
  }
  .wrap {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1100px) {
      display: block;
    }
    .title {
      width: 25%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h2 {
        color: var(--black);
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          text-align: center;
        }
        @media screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      p {
        color: var(--black);
        @media screen and (max-width: 1100px) {
          text-align: center;
        }
      }
    }
    .content {
      width: 55%;
      line-height: 2rem;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h3 {
        color: var(--main);
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        } 
      }
      p {
        line-height: 1.5rem;
        margin-bottom: 5px;;
      }
    }
    .lp-link-sp {
      display: none;
      @media screen and (max-width: 1100px) {
        display: block;
        width: 75%;
        text-align: center;
        color: var(--main);
        font-size: 20px;
        font-weight: 700;
        padding: 15px 0;
        margin: 40px auto 0 auto;
        text-decoration: none;
        border-radius: 10px;
        background-color: var(--sub3);
      }
    }
    .video-content {
      width: 20%;
      position: relative;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 80%;
        margin: auto;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .absolute-content {
          position: absolute;
          top: -120px;
          right: -15%;
          width: 130%;
          text-decoration: none;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          cursor: default;
          @media screen and (max-width: 1100px) {
            display: flex;
            position: static;
            width: 100%;
            margin: 20px auto;
          }
          video {
            width: 100%;
            clip-path: circle(32% at 50% 50%);
            cursor: pointer;
            margin-bottom: 10px;
          }
          p {
            display: block;
            color: var(--main);
            text-align: center;
            padding: 5px 15px;
            border-radius: 10px;
            background-color: var(--sub3);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            @media screen and (max-width: 1100px) {
              padding: 10px 20px;
            }
          }
      }
    }
  }
}
</style>