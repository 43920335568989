import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { setTitle, setDescription } from '@/mixins/index.js'

import Home from '../views/HomeUcreate.vue'
import RecruitLp from '@/views/RecruitLp.vue'
import ClosedRecruitLp from '@/views/ClosedRecruitLp.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '株式会社U-CREATE｜ウェブマーケティング×ホームページ制作',
      description: 'ウェブマーケティング×ホームページ制作なら岐阜市にある株式会社U-CREATE（ユークリエイト）におまかせください。市場分析・競合調査をしっかり行い、集客・販売強化、求人強化を目指します。クライアントの目的を達成するホームページ制作を得意としています。制作後のSEO対策・広告運用・SNS運用・名刺作成・パンフレット作成の支援も可能です。'
    }
  },
  {
    path: '/recruit-service',
    name: 'recruit-service',
    component: RecruitLp,
    meta: {
      title: '【求人強化】今より必ず応募が増える求人支援サービス｜株式会社U-CREATE',
      description: '求人・採用にお困りの経営者の方へ。株式会社U-CREATEの求人支援サービスをご紹介します。これまでの支援実績で、応募数・採用数の増加の成功率100%です。初回無料相談を承っていますので、興味がありましたら、ぜひお申し込みください。',
    }
  },
  {
    path: '/closed/recruit',
    name: 'closed-recruit',
    component: ClosedRecruitLp,
    meta: {
      title: '【求人強化】今より必ず応募が増える求人支援サービス｜株式会社U-CREATE',
      description: '求人・採用にお困りの経営者の方へ。株式会社U-CREATEの求人支援サービスをご紹介します。これまでの支援実績で、応募数・採用数の増加の成功率100%です。初回無料相談を承っていますので、興味がありましたら、ぜひお申し込みください。',
      noindex: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  
  if (to.meta.noindex) {
    document.head.querySelector('meta[name="robots"]').setAttribute('content', 'noindex nofollow')
  } else {
    document.head.querySelector('meta[name="robots"]').setAttribute('content', 'index follow')
  }

  store.dispatch("setRootUrl");
  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router
